const common = {
  s3: {
    REGION: 'us-east-1',
    BUCKET: 'pt-uploads-qa-attachmentsbucket-1j3jcsbjtpdvd'
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_pjXSKwZER',
    APP_CLIENT_ID: '6v0tieukti3gtglmdr5fe9hdmi',
    IDENTITY_POOL_ID: 'us-east-1:e8a85a65-e1a3-40a9-8ff8-4118f58647ae'
  }
}

const config = {
  dev: {
    apiGateway: {
      REGION: 'us-east-1',
      URL: 'http://localhost:3000/graphql',
      API_KEY: 'd41d8cd98f00b204e9800998ecf8427e'
    },
    ...common
  },
  qa: {
    apiGateway: {
      REGION: 'us-east-1',
      
      URL: 'https://5bw4j7imi4.execute-api.us-east-1.amazonaws.com/qa/graphql',
      API_KEY: 'MfT7bsdPbE8oz7Q3oEJYl1YKmx1yl90D17u5hjK9'
    },
    ...common
  },
  production: {
    s3: {
      REGION: 'us-east-1',
      BUCKET: 'pt-uploads-prod-attachmentsbucket-vfxhn0kfp8zx'
    },
    apiGateway: {
      REGION: 'us-east-1',
      URL: 'https://jzf7l03u1g.execute-api.us-east-1.amazonaws.com/prod/graphql',
      API_KEY: 'tF186BZOZPaZNJ7GmpkyMasxxnaVuk56dSPKbe2c'
    },
    cognito: {
      REGION: 'us-east-1',
      USER_POOL_ID: 'us-east-1_pRy9Sovkw',
      APP_CLIENT_ID: 'nbmnp5vqr8pm1jii649hqfjhn',
      IDENTITY_POOL_ID: 'us-east-1:70faf75f-41a9-451b-bc33-b5b18a382bcb'
    }
  }
}
const key = process.env.REACT_APP_CONFIG || process.env.NODE_ENV || 'production'
// console.log('loading env ', key, config[key])

export default config[key]
