import React from 'react'

import Amplify, { Auth, Hub } from 'aws-amplify'
import { withAuthenticator } from 'aws-amplify-react' // or 'aws-amplify-react-native';

import { Container } from 'react-bootstrap'

import { Link, BrowserRouter } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'

import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createPersistedQueryLink } from "apollo-link-persisted-queries"
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { withClientState } from 'apollo-link-state'
import { ApolloLink, Observable } from 'apollo-link'


import { ApolloProvider } from '@apollo/react-hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faHome } from '@fortawesome/pro-regular-svg-icons'


import './App.css'

import config from './config'

const Home = React.lazy(() => import('./containers/home/home.js'))

const NotFound = () => <h3>not found...</h3>


const request = async (operation) => {
  const token = (await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken
 
  operation.setContext({
    headers: {
      authorization: token,
      'x-api-key': config.apiGateway.API_KEY
    }
  })
}
const requestLink = new ApolloLink((operation, forward) =>
  new Observable(observer => {
    let handle;
    Promise.resolve(operation)
      .then(oper => request(oper))
      .then(() => {
        handle = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      })
      .catch(observer.error.bind(observer));

    return () => {
      if (handle) handle.unsubscribe();
    };
  })
)
const cache = new InMemoryCache()
const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      try {
        if (process.env.NODE_ENV === 'development') {
          if (graphQLErrors )
            graphQLErrors.forEach(({ message, locations, path }) =>
              console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
              ),
            )
          if (networkError) console.log(`[Network error]: ${networkError}`)  
        }  
      } catch(x) {}
      
      
    }),
    createPersistedQueryLink(),
    requestLink,
    withClientState({
      defaults: {
        isConnected: true
      },
      resolvers: {
        Mutation: {
          updateNetworkStatus: (_, { isConnected }, { cache }) => {
            cache.writeData({ data: { isConnected }});
            return null;
          }
        }
      },
      cache
    }),
    new HttpLink({
      uri: config.apiGateway.URL,
      credentials: 'same-origin'
    })
  ]),
  cache
})

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  },
  API: {
    graphql_endpoint: config.apiGateway.URL,
    graphql_headers: async () => ({
      Authorization: `${(await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken}`,
      'x-api-key': config.apiGateway.API_KEY
    })
  }
})

const listener = (data) => {
    switch (data.payload.event) {
      case 'signIn':
          console.log('user signed in'); 
          break;
      case 'signUp':
          console.log('user signed up');
          break;
      case 'signOut':
        console.log('user signed out')
        client.cache.reset().then(
          () => {
            client.clearStore().then(
              () => client.resetStore().then(
                () => window.location='/'
              )
            )    
          }
        )
        
          break;
      case 'signIn_failure':
          console.log('user sign in failed');
          break;
      case 'configured':
        console.log('the Auth module is configured')
        break;
      default:
        console.log('Hub listener data ', data)
    }
}
Hub.listen('auth',listener)

const App = ({ history }) => {
  
  return (
      <ApolloProvider client={client}>
          <BrowserRouter>
            <React.Suspense fallback={<div>loading...</div>}>
              <Container className="m-auto">
                <Link to="/">
                <FontAwesomeIcon icon={faHome} size="lg" className="mr-2" />
                </Link>
                <Switch>
                  <Route path="/">
                    <Home/>
                  </Route>
                  <Route>
                    <NotFound/>
                  </Route>              
                </Switch>  
              </Container>
            </React.Suspense>
          </BrowserRouter>  
      </ApolloProvider>
  )
}

export default withAuthenticator(App, true)
